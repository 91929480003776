/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Star16 = ({ className }) => {
  return (
    <svg
      className={`star-16 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 24 20"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.0735 1.28117C11.4113 0.449571 12.5887 0.449573 12.9265 1.28118L15.1144 6.66809C15.2675 7.04516 15.6339 7.2918 16.0409 7.2918H22.5554C23.5769 7.2918 23.9411 8.64301 23.0579 9.15636L18.1998 11.9801C17.7692 12.2304 17.5884 12.7594 17.7758 13.2209L19.7155 17.9966C20.0743 18.88 19.1108 19.7166 18.2865 19.2375L12.5025 15.8757C12.1918 15.6951 11.8082 15.6951 11.4975 15.8757L5.71354 19.2375C4.88923 19.7166 3.92575 18.88 4.28453 17.9966L6.22418 13.2209C6.41161 12.7594 6.23084 12.2304 5.8002 11.9801L0.942098 9.15636C0.058887 8.64301 0.423051 7.2918 1.44461 7.2918H7.95911C8.36608 7.2918 8.73246 7.04516 8.88561 6.66809L11.0735 1.28117Z"
        fill="#C8982A"
      />
    </svg>
  );
};
