import React from "react";
import { EstudoDeCaso } from "../../components/EstudoDeCaso";
import { FaleComUm } from "../../components/FaleComUm";
import { NovaHero } from "../../components/NovaHero";

import React, { useEffect, useState } from "react";
import "./style.css";
import { HeroCaysa } from "../HeroCaysa/HeroCaysa";

  
  export const Movimento = () => {


  return (
    <div className="novosmovimentos">

      <div className="presaum">


<div className=""><HeroCaysa/> 
<div className="box">           </div>

</div>





<div className="partedois">


</div>


<div className="partetres"></div>

</div>
    </div>
  );
};
